import {type AxiosInstance} from "axios"
import * as iltypes from "src/interfaces/InleagueApiV1"
import { CoachTitle, Datelike, Guid, Integerlike, UserID } from "src/interfaces/InleagueApiV1"
import { rpc } from "./Rpc"

export interface MatchReport_Team {
  halftimeScore: "" | number,
  name: string,
  /**
   * Empty string means "unknown/not-recorded-yet"
   */
  score: "" | number,
}

export interface MatchReport_Referee {
  /**
   * posName is "position name", and is an arbitrary label assigned by a league, for a referee position, for some competition/division
  */
 posName: string,
 /**
  * Detail will be missing, if there is no ref assignment for this position. Otherwise, it is present.
  */
 detail?: MatchReport_RefereeDetail
}

export interface MatchReport_RefereeDetail {
  email: string
  firstName: string,
  lastName: string,
  userID: iltypes.Guid,
}

export interface MatchReport_ScoreTransactionCard {
  label: string,
  playerFirstName: string,
  playerLastName: string,
  pointValue: number
  teamName: string,
}

export interface MatchReport {
  cards: MatchReport_ScoreTransactionCard[],
  comment: string,
  fieldName: string,
  gameStart: iltypes.DateTimelike,
  homeTeam: MatchReport_Team,
  /**
   * There are always exactly four of these.
   */
  referees: [
    MatchReport_Referee,
    MatchReport_Referee,
    MatchReport_Referee,
    MatchReport_Referee
  ]
  visitorTeam: MatchReport_Team,
}

export async function getMatchReport(axios: AxiosInstance, args: {gameID: iltypes.Guid}) : Promise<MatchReport> {
  const response = await axios.get(`v1/matchReport/${args.gameID}`);
  return response.data.data;
}

/**
 * Game endpoints generally expose a way to retrieve a list of these per game, as an expandable
 */
export interface AdhocCoachInfo {
  which: "visitor" | "home",
  teamID: iltypes.Guid,
  title:
    | "Assistant"
    | "Co-Coach"
    | "Head Coach"
  firstName: string,
  lastName: string,
  email: string,
}

type GameExpandables =
  | "refereeDetails"
  | "familyConflicts"
  | "adhocCoachInfo"

/**
 * divIDs is optional; not providing a divID list is equivalent to supplying every divID
 * `gameID` supports looking up some single game
 */
export async function getGames(axios: AxiosInstance, args: {
  competitionID: iltypes.Integerlike,
  startDate?: iltypes.Datelike,
  endDate?: iltypes.Datelike,
  divIDs?: iltypes.Guid[]
  gameID?: iltypes.Guid,
  expand?: readonly GameExpandables[]
}) : Promise<Game[]> {
  const params : Record<string, string | undefined> = {
    startDate: args.startDate,
    endDate: args.endDate,
    divIDs: args.divIDs?.join(","),
    gameID: args.gameID,
    expand: args.expand?.join(","),
  }
  const response = await axios.get(`/v1/games/${args.competitionID}`, {params})
  return response.data.data;
}

export interface GetPublicationDatesResponse {
  competition: {
    competitionUID: Guid,
    competition: string,
    publishDate: "" | Datelike
  },
  divisions: {
    divID: Guid,
    publishDate: Datelike,
    gender: string,
    divNum: Integerlike,
  }[]
}

export async function getPublicationDates(ax: AxiosInstance, args: {competitionUID: Guid, divIDs: Guid[]}) : Promise<GetPublicationDatesResponse> {
  const response = await ax.get(`v1/games/publicationDates`, {params: {competitionUID: args.competitionUID, divIDs: args.divIDs}});
  return response.data.data;
}

/**
 * Wrapper around `getGames`, targeting a single game. The target endpoint still requires the correct competitionID, although
 * we could refactor the backend to not require such a thing, because `gameID` by itself is a uniquely identifying PK for a game.
 * If we do not find the target game, the underlying endpoint returns an empty array, as opposed to raising a 404;
 * in such a case the semantics here are to return null.
 */
export async function getGame(axios: AxiosInstance, args: {
 competitionID: iltypes.Integerlike,
 gameID: iltypes.Guid,
 expand?: readonly GameExpandables[]
}) : Promise<Game | null> {
  const games = await getGames(axios, args);
  if (games.length === 0) {
    return null;
  }
  else if (games.length === 1) {
    return games[0]
  }
  else {
    throw Error(`expected 0 or 1 game but got ${games.length}; gameID=${args.gameID}`)
  }
}

// todo: unify more properties into base object, and guarantee that all endpoints satisfy the common contract
export interface GameBase {
  gameID: iltypes.Guid,
  competitionUID: iltypes.Guid,
  divID: iltypes.Guid,
  homeGoals: "" | iltypes.Integerlike,
  visitorGoals: "" | iltypes.Integerlike,
}

// todo: dedupe with VisitorTeam
export interface GameTeamDetail {
  teamID: string;
  team: string;
  placeholder?: boolean;
  region?: number;
  teamCity: string;
  teamName: string;
}

// do we need aliases for this? maybe we expect they can sometimes differ in some way?
export type HomeTeam = GameTeamDetail;
export type VisitorTeam = GameTeamDetail;

// todo: where is this used? Is it Pick<Team, ...>?
export interface Player {
  registrationID:  string;
  uniform:         string;
  playerFirstName: string;
  playerLastName:  string;
  aysoID:          string;
  childID:         string;
}

// todo: where is this used? Is it Pick<Team, ...>
export interface Team {
  team:          string;
  goals:         number;
  goalsHalfTime: number;
  players:       Player[];
  fullName:      string;
  teamName:      string;
  ID:            string;
}

export interface CoachDetail {
    firstname: string;
    lastname: string;
    /**
     * Email may not be included if the league has configured coach emails to not be publically available.
     * See backend property `paranoidCoaches`
     */
    email?: string;
    title: iltypes.CoachTitle;
    userID: string;
    teamID: string;
    isHeadCoach: number;
}

// do we need aliases for this? maybe we expect they can sometimes differ in some way?
export type HomeCoach = CoachDetail;
export type VisitorCoach = CoachDetail;

export interface RefInfo {
  ID:            string;
  FirstName:     string;
  LastName:      string;
  AYSOID?:        string;
  lastEAYSOYear?: string;
  DOB: Datelike,
}

export interface FamilyConflict {
  divID:           string;
  userID:          string;
  team:            string;
  position:        string;
  childID:         string;
  gameNum:         number;
  conflictID:      string;
  gameID:          string;
  familyID:        string;
  teamID:          string;
  playerLastName:  string;
  playerFirstName: string;
}

export interface Game extends GameBase {
  homeName?: string | undefined;
  visitorName?: string | undefined;
  homeGoalsHalftime:      string;
  poolID:                 string;
  fieldUID:               string;
  scoreEntryDate:         string;
  scoreUserLastName:      string;
  visitorTeamDesignation: string;
  updatedBy:              string;
  dateCreated:            string;
  fieldName:              string;
  gameEnd:                string;
  comment:                string;
  scoreUserFirstName:     string;
  ARLock:                 number;
  gameDate:               string;
  timeOffset:             number;
  ARRegion:               string;
  genderNeutral:          number;
  parked:                 number;
  doPointsCount:          number;
  gameNum:                number;
  divNum:                 number;
  /**
   * Some endpoints provided this
   */
  divGender?:              string,
  competitionSeasonUID:   string;
  homeTeamName:           string;
  visitorGoalsHalftime:   string;
  division:               string;
  homeTeamDesignation:    string;
  familyConflicts:        FamilyConflict[];
  visitorTeamName:        string;
  MentorLock:             number;
  home:                   string;
  dateUpdated:            string;
  CRRegion:               string;
  MARegion:               string;
  AR2Region:              string;
  scoreEntryUser:         string;
  roundID:                string;
  CRLock:                 number;
  createdBy:              string;
  AR2Lock:                number;
  clientID:               string;
  refComment:             string;
  scoreComment:           string;
  gameID:                 string;
  fieldID:                number;
  gameStart:              string;
  visitor:                string;
  blockFromMatchmaker:    number;
  playoff:                number;
  /**
   * Sometimes available, from some endpoints.
   * TODO: Pin down which endpoints, and strongly type them.
  */
  userIsAuthorizedForMatchReport?: iltypes.Numbool
  /**
   * expandable
   */
  adhocCoachInfo?: AdhocCoachInfo[],
  ref1: RefInfo | UserID | "",
  ref2: RefInfo | UserID | "",
  ref3: RefInfo | UserID | "",
  ref4: RefInfo | UserID | "",
  ref1Vol: RefInfo | UserID | "",
  ref2Vol: RefInfo | UserID | "",
  ref3Vol: RefInfo | UserID | "",
  ref4Vol: RefInfo | UserID | "",
}

export interface TransactionType {
  categoryLabel: string;
  rank:          string;
  points:        number;
  categoryID:    string;
  typeID:        string;
  clientID:      string;
  transactionID: string;
  competitionID: number;
  label:         string;
}

export async function createRefereeSignupRequest(axios: AxiosInstance, args: {gameID: iltypes.Guid, refPosition: number}) : Promise<void> {
  await axios.post(`v1/refereeAssignment/requestGame/${args.gameID}/${args.refPosition}`)
}

export async function cancelRefereeSignupRequest(axios: AxiosInstance, args: {gameID: iltypes.Guid, refPosition: number, sendConfirmationEmail: boolean}) : Promise<void> {
  const {gameID, refPosition, sendConfirmationEmail} = args
  await axios.delete(`v1/refereeAssignment/requestGame/${gameID}/${refPosition}`, {params: {sendConfirmationEmail}})
}

export async function approveRefereeAssignment(axios: AxiosInstance, args: {gameID: iltypes.Guid, refPosition: number, sendConfirmationEmail: boolean}) : Promise<void> {
  await axios.post(`v1/refereeAssignment/approve/${args.gameID}/${args.refPosition}`, {sendConfirmationEmail: args.sendConfirmationEmail});
}

export async function cancelRefereeAssignment(axios: AxiosInstance, args: {gameID: iltypes.Guid, refPosition: number, sendConfirmationEmail: boolean}) : Promise<void> {
  await axios.delete(`v1/refereeAssignment/approve/${args.gameID}/${args.refPosition}`, {params: {sendConfirmationEmail: args.sendConfirmationEmail}})
}

/**
 * "area coaches" are different from "coaches" in that they represent coaches for "foreign" teams having no associated inLeague user.
 * coach data here MUST share a common supertype with "non-area" `CoachDetail`. Unfortunately casing is different in half the places, depending on where data
 * is pulled from, so we kludgily support "both" property name cases; ideally, the api would be tweaked so that all "coachlike" shapes are camelCase (or all lowercase;
 * doesn't matter, just needs to be the same everywhere).
 * Both `homeAreaCoaches` and `visitorAreaCoaches` may be empty. It is not possible to distinguish here between:
 *  - "has an particular {home, visitor} area team but no area coaches for that area team"
 *  - "has no {home, visitor} area team and therefore there are no associated area coaches"
 */
export interface AreaCoachGameData<CaseKludge extends AreaCoachDetail_lowercase | AreaCoachDetail_camelCase> {
  gameID: Guid,
  homeAreaCoaches: CaseKludge[]
  visitorAreaCoaches: CaseKludge[]
}

// just a few properties are in lowercase, to match shapes elsewhere
// eventually we'd like the lowercase props to be camelCased; fixing `CoachDetail` should then break this at compile alerting us this is no longer necessary
export type AreaCoachDetail_lowercase =
  & Pick<CoachDetail,
    | "firstname"
    | "lastname"
    | "email"
    | "title"
  >
  & {coachID: Guid}

// unfortunately it's about the same amount of work to change everything to camelCase or everything to alllowercase
// we're mostly moving towards camelCase, when possible the backend should return camelCase things
export type AreaCoachDetail_camelCase = {
  firstName: string,
  lastName: string,
  email?: string,
  title: CoachTitle,
  /**
   * this is "area coach PK", rather than "non-area coach PK"
   */
  coachID: Guid
}

export async function getAreaCoachesForGames(axios: AxiosInstance, args: {gameIDs: Guid[]},) : Promise<AreaCoachGameData<AreaCoachDetail_camelCase>[]> {
  const response = await axios.get(`v1/games/areaCoaches`, {params: {gameIDs: args.gameIDs.join(",")}})
  return response.data.data;
}

/**
 * See comments on `AreaCoachGameData`
 */
export function areaCoachCamelToLower(v: AreaCoachGameData<AreaCoachDetail_camelCase>) : AreaCoachGameData<AreaCoachDetail_lowercase> {
  return {
    gameID: v.gameID,
    homeAreaCoaches: v.homeAreaCoaches.map(mungeOne),
    visitorAreaCoaches: v.visitorAreaCoaches.map(mungeOne),
  }

  function mungeOne(v: AreaCoachDetail_camelCase) : AreaCoachDetail_lowercase {
    return {
      firstname: v.firstName,
      lastname: v.lastName,
      email: v.email,
      title: v.title,
      coachID: v.coachID,
    }
  }
}

export const canViewUnpublishedGames = rpc<void, boolean>("get", "v1/game/canViewUnpublishedGames")
